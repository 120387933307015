import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

// Wrapper around radar chart
// https://www.npmjs.com/package/ng2-charts?activeTab=readme
// that uses
// https://www.chartjs.org/docs/master/charts/radar.html

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chart') radarChartCanvas: ElementRef<HTMLCanvasElement>;

  public radarChart: Chart;
  @Input() public config: ChartConfiguration;
  constructor() {}

  public ngAfterViewInit(): void {
    Chart.register(...registerables);
    this.radarChart = new Chart(
      this.radarChartCanvas.nativeElement,
      this.config
    );
  }

  public ngOnDestroy(): void {
    this.radarChart?.destroy();
  }
}
