import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarAction, ToolbarService } from '../../../../shared';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly toolbarService: ToolbarService
  ) {}

  public ngOnInit(): void {
    this.toolbarService.setActions([this.getCVAction()]);
  }

  public getCVAction(): ToolbarAction {
    return {
      label: 'CV',
      icon: 'cv',
      callback: () => this.navigateToCV(),
    };
  }

  public navigateToCV(): void {
    this.router.navigate(['cv']);
  }
}
