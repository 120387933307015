import { Contact, ContactType } from '../../shared/models';

export const contactInformation: Contact[] = [
  {
    type: ContactType.EMAIL,
    value: 'phoquintas@gmail.com',
  },
  // {
  //   type: ContactType.PHONE,
  //   value: '+351 912026187',
  // },
  {
    type: ContactType.LINKED_IN,
    value: 'https://www.linkedin.com/in/pedro-o-quintas/',
  },
];
