<div class="cv-container row">
  <div class="col">
    <div class="section-container intro-container">
      <app-cv-introduction></app-cv-introduction>
    </div>
    <div class="section-container experiences-container">
      <app-cv-work-experience [experiences]="cv.workExperiences"></app-cv-work-experience>
    </div>
  </div>

  <div class="col">
    <div class="section-container education-container">
      <app-cv-education [experiences]="cv.education"></app-cv-education>
    </div>
    <div class="section-container personal-projects">
      <app-cv-personal-projects [experiences]="cv.personalProjects"></app-cv-personal-projects>
    </div>
    <div class="section-container skills-container">
      <app-cv-skills></app-cv-skills>
    </div>
  </div>
</div>
