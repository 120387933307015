<div *ngIf="!isPrintingPdf()" class="app-container app">
  <app-toolbar></app-toolbar>
  <div class="app-body">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>

<div *ngIf="isPrintingPdf()" class="app-container pdf">
  <table>
    <thead>
      <!-- Will print at the top of every page -->
    </thead>

    <tbody>
      <!-- Page content -->
      <router-outlet></router-outlet>
    </tbody>

    <tfoot>
      <!-- Will print at the bottom of every page -->
    </tfoot>
  </table>
</div>
