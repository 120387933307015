import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BaseChartDirective } from 'ng2-charts';
import * as components from './components';
import * as services from './services';

const sharedComponents = [
  components.ButtonComponent,
  components.FooterComponent,
  components.PrivacyComponent,
  components.TermsComponent,
  components.ToolbarComponent,
  components.PdfClickableComponent,
  components.PdfLinkComponent,
  components.IconComponent,
  components.RadarChartComponent,
  components.PolarAreaChartComponent,
  components.SkillGaugeComponent,
  components.ClickableSkillComponent,
  components.TextButtonComponent,
];

const providers = [
  services.DateUtilsService,
  services.PdfService,
  services.ToolbarService,
  services.ChartUtilsService,
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    BrowserModule,
    CommonModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatToolbarModule,
    BaseChartDirective,
  ],
  providers: [...providers],
  exports: [
    ...sharedComponents,
    BrowserModule,
    CommonModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatToolbarModule,
    BaseChartDirective,
  ],
})
export class SharedModule {}
