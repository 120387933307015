<div class="section-header">
  <div class="section-header-title">Education</div>
</div>
<div class="section-body">
  <div class="item-card" *ngFor="let experience of experiences">
    <div class="subsection-header clickable-container" (click)="openNewTab(experience.institution.link)">
      <app-pdf-clickable [link]="experience.institution.link">
        <div class="subsection-image">
          <img
            [src]="'../../../assets/media/images/company-logos/' + experience.institution.image"
            alt="company-image"
            class="company-image"
          />
        </div>
      </app-pdf-clickable>
      <div class="subsection-name">
        <app-pdf-link [link]="experience.institution.link" [label]="experience.institution.name"> </app-pdf-link>
      </div>
    </div>
    <div class="subsection-body divide">
      <div class="item-card" *ngFor="let degree of experience.degrees">
        <div class="subsection-header degree-header">
          <div class="position-details">
            <div class="position-name">
              {{ degree.name }}
            </div>
            <div class="position-dates">
              {{ degree.startDate | date : "MMM YYYY" }} -
              {{ degree.endDate ? (degree.endDate | date : "MMM YYYY") : "ongoing" }} ({{
                calculateTimeAtPosition(degree)
              }})
            </div>
          </div>
        </div>
        <div *ngIf="degree.documents?.length" class="documents-list-container degree-documents">
          <div class="subsection-header document-header">Documents</div>
          <div
            class="document-container clickable-container"
            *ngFor="let document of degree.documents"
            (click)="openNewTab(document.link)"
            [matTooltip]="document.description"
          >
            <div class="subsection-name">
              <app-pdf-link [link]="document.link" [label]="document.name"> </app-pdf-link>
            </div>
            <div *ngIf="document.image" class="subsection-image" [ngClass]="{ 'display-none': !document.image }">
              <img
                [src]="'../../../assets/media/images/document-logos/' + document.image"
                alt="document-image"
                class="company-image"
              />
            </div>
          </div>
        </div>
        <div *ngIf="degree.projects?.length" class="projects-list-container degree-projects">
          <div class="subsection-header project-header">Highlighted Projects</div>
          <div
            class="project-container clickable-container"
            *ngFor="let project of degree.projects"
            (click)="openNewTab(project.link)"
            [matTooltip]="project.description"
          >
            <div class="subsection-name">
              <app-pdf-link [link]="project.link" [label]="project.name"> </app-pdf-link>
            </div>
            <div *ngIf="project.image" class="subsection-image" [ngClass]="{ 'display-none': !project.image }">
              <img
                [src]="'../../../assets/media/images/project-logos/' + project.image"
                alt="project-image"
                class="company-image"
              />
            </div>
          </div>
        </div>
        <!--
                <div class="subsection-body">
                    <div class="subsection-description">
                        {{ position.description }}
                    </div>
                    <div *ngIf="position.techSkills.length" class="skills-list">
                        {{ listToText(position.techSkills) }}
                    </div>
                    <div *ngIf="position.managementSkills.length" class="skills-list management-skills">
                        {{ listToText(position.managementSkills) }}
                    </div>
                </div>
                -->
      </div>
    </div>
  </div>
</div>
