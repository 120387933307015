<div class="section-header">
  <div class="section-header-title">Personal Projects</div>
</div>
<div class="section-body">
  <div class="item-card {{ experience.hideInPrintCV ? 'show-app' : '' }}" *ngFor="let experience of experiences">
    <div class="subsection-body">
      <div class="item-card">
        <div class="subsection-header project-header">
          <div class="position-details">
            <div class="position-name">
              {{ experience.name }}
            </div>
            <div class="position-dates">
              {{ experience.startDate | date : "MMM YYYY" }} -
              {{ experience.endDate ? (experience.endDate | date : "MMM YYYY") : "ongoing" }} ({{
                calculateTimeAtPosition(experience)
              }})
            </div>
          </div>
          <div class="project-links">
            <app-pdf-clickable [link]="link.link" *ngFor="let link of experience.links">
              <app-button [icon]="link.icon" (click)="openNewTab(link.link)" [tooltip]="link.name" size="small" />
            </app-pdf-clickable>
          </div>
        </div>
        <div class="subsection-body">
          <div class="subsection-description">
            {{ experience.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
