import { NgModule } from '@angular/core';
import * as c from './components';
import { SharedModule } from '../../shared';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    component: c.HomeComponent,
  },
];

const components = [
  c.TimelineComponent,
  c.HomeComponent,
  c.TimelineCardShellComponent,
  c.WorkExperienceCardComponent,
  c.PersonalExperienceCardComponent,
  c.EducationCardComponent,
  c.SkillsChartComponent,
  c.WelcomeComponent,
];

@NgModule({
  declarations: [...components],
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [...components],
  providers: [],
})
export class HomeModule {}
