import { Component, OnInit } from '@angular/core';
import {
  educationLogs,
  workExperienceLogs,
  personalProjectLogs,
  EducationDegree,
  EducationLog,
  WorkExperienceLog,
  WorkExperiencePosition,
  PersonalProject,
  SortDirection,
  BaseTimelineEvent,
  EducationTimelineEvent,
  WorkExperienceTimelineEvent,
  PersonalProjectTimelineEvent,
  TimelineEventType,
  DateUtilsService,
} from '../../../../shared';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  public description = 'This is my journey';
  public timelineEvents: BaseTimelineEvent[] = [];
  public eventTypes = Object.values(TimelineEventType).sort();
  public filteredEventTypes = Object.values(TimelineEventType);

  private cv = {
    experiences: workExperienceLogs,
    education: educationLogs,
    personalProjects: personalProjectLogs,
  };
  constructor() {}

  public ngOnInit(): void {
    this.loadTimelineEvents();
    this.sortTimelineEvents();
  }

  public isWorkExperience(timelineEvent: BaseTimelineEvent): boolean {
    return timelineEvent.type == TimelineEventType.WORK_EXPERIENCE;
  }

  public isEducation(timelineEvent: BaseTimelineEvent): boolean {
    return timelineEvent.type == TimelineEventType.EDUCATION;
  }

  public isPersonalProject(timelineEvent: BaseTimelineEvent): boolean {
    return timelineEvent.type == TimelineEventType.PERSONAL_PROJECT;
  }

  public experienceToTimelineEvent(
    workExperience: WorkExperienceLog,
    position: WorkExperiencePosition
  ): WorkExperienceTimelineEvent {
    return {
      start: position.startDate,
      end: position.endDate,
      name: position.position,
      entity: workExperience.company,
      description: position.description,
      type: TimelineEventType.WORK_EXPERIENCE,
      imageUrl: position.generalImage,
      position,
      workExperience,
    };
  }

  public educationToTimelineEvent(
    education: EducationLog,
    degree: EducationDegree
  ): EducationTimelineEvent {
    return {
      start: degree.startDate,
      end: degree.endDate,
      name: degree.name,
      entity: education.institution,
      type: TimelineEventType.EDUCATION,
      imageUrl: degree?.generalImage,
      education,
      degree,
    };
  }

  public personalProjectToTimelineEvent(
    personalProject: PersonalProject
  ): PersonalProjectTimelineEvent {
    return {
      start: personalProject.startDate,
      end: personalProject.endDate,
      name: personalProject.name,
      description: personalProject.description,
      type: TimelineEventType.PERSONAL_PROJECT,
      imageUrl: personalProject?.generalImage,
      personalProject,
    };
  }

  public calculateTimeAtEvent(timelineEvent: BaseTimelineEvent): string {
    return DateUtilsService.timePeriodString(
      timelineEvent.start,
      timelineEvent.end
    );
  }

  public toggleEventType(eventType: TimelineEventType): void {
    if (this.isEventTypeFiltered(eventType)) {
      // If event type is included, then remove it
      this.filteredEventTypes = this.filteredEventTypes.filter(
        (e) => e !== eventType
      );

      if (!this.filteredEventTypes.length) {
        this.filteredEventTypes = [...this.eventTypes];
      }
    } else {
      // Otherwise include it
      this.filteredEventTypes.push(eventType);
    }
  }

  public isWorkEventTypeFiltered(): boolean {
    return this.isEventTypeFiltered(TimelineEventType.WORK_EXPERIENCE);
  }

  public isEducationEventTypeFiltered(): boolean {
    return this.isEventTypeFiltered(TimelineEventType.EDUCATION);
  }

  public isPersonalProjectEventTypeFiltered(): boolean {
    return this.isEventTypeFiltered(TimelineEventType.PERSONAL_PROJECT);
  }

  public showEventCard(timelineEvent: BaseTimelineEvent): boolean {
    return (
      (this.isWorkExperience(timelineEvent) &&
        this.isWorkEventTypeFiltered()) ||
      (this.isEducation(timelineEvent) &&
        this.isEducationEventTypeFiltered()) ||
      (this.isPersonalProject(timelineEvent) &&
        this.isPersonalProjectEventTypeFiltered())
    );
  }

  public isEventTypeFiltered(eventType: TimelineEventType): boolean {
    return this.filteredEventTypes.includes(eventType);
  }

  private loadTimelineEvents(): void {
    for (const experience of this.cv.experiences) {
      for (const position of experience.positions) {
        this.timelineEvents.push(
          this.experienceToTimelineEvent(experience, position)
        );
      }
    }
    for (const education of this.cv.education) {
      for (const degree of education.degrees) {
        this.timelineEvents.push(
          this.educationToTimelineEvent(education, degree)
        );
      }
    }
    for (const personalProject of this.cv.personalProjects) {
      this.timelineEvents.push(
        this.personalProjectToTimelineEvent(personalProject)
      );
    }
  }

  private sortTimelineEvents(
    direction: SortDirection = SortDirection.DESC
  ): void {
    if (direction == SortDirection.ASC) {
      this.timelineEvents.sort((a, b) => a.start.getTime() - b.start.getTime());
    } else if (direction == SortDirection.DESC) {
      this.timelineEvents.sort((a, b) => b.start.getTime() - a.start.getTime());
    }
  }
}
