import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as sharedComponents from './shared/components';

const routes: Routes = [
  // { path: 'soon', component: components.SoonComponent, pathMatch: 'full' },
  // {
  //   path: 'arcade', component: components.ArcadeComponent,
  //   children: [
  //     {
  //       path: 'one-of-many',
  //       component: OneOfManyComponent
  //     }
  //   ]
  // },
  // { path: 'gallery', component: components.GalleryComponent, pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
  },
  {
    path: 'cv',
    loadChildren: () =>
      import('./features/cv/cv.module').then((m) => m.CvModule),
    pathMatch: 'full',
  },
  {
    path: 'terms',
    component: sharedComponents.TermsComponent,
    pathMatch: 'full',
  },
  {
    path: 'privacy',
    component: sharedComponents.PrivacyComponent,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
