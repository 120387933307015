<div class="skills-wrapper">
  <div class="skills-chart-header">
    <div class="skills-chart-description">Skills</div>
  </div>
  <div class="skills-chart-body">
    <!-- <app-radar-chart [config]="techSkillsRadarChartConfig"></app-radar-chart> -->
    <div class="skills-chart tech-skills">
      <app-polar-area-chart [config]="techSkillsPolarAreaChartConfig"></app-polar-area-chart>
    </div>
    <div class="skills-chart management-skills">
      <app-polar-area-chart [config]="managementSkillsPolarAreaChartConfig"></app-polar-area-chart>
    </div>
  </div>
</div>
