<div class="timeline-event-card row">
  <div class="timeline-card-content col">
    <div class="timeline-card-header-container">
      <div class="timeline-card-header">
        <div class="timeline-card-header-title">
          <div class="timeline-event-name">
            {{ timelineEvent.name }}
            <ng-container *ngIf="timelineEvent.entity">
              <span class="institution-link" (click)="openNewTab(timelineEvent.entity.link)">
                {{ " at " }}{{ timelineEvent.entity.name }}
              </span>
            </ng-container>
          </div>
        </div>
        <div class="timeline-event-time">
          <div class="experience-dates">
            {{ timelineEvent.start | date : "MMM YYYY" }} -
            {{ timelineEvent.end ? (timelineEvent.end | date : "MMM YYYY") : "ongoing" }}
            ({{ calculateTimeAtEvent(timelineEvent) }})
          </div>
        </div>
      </div>
      <div class="card-header" *ngIf="cardHeader">
        <ng-container *ngTemplateOutlet="cardHeader"></ng-container>
      </div>
    </div>

    <div *ngIf="timelineEvent?.description" class="timeline-event-description">
      {{ timelineEvent.description }}
    </div>

    <div class="card-body" *ngIf="cardBody">
      <ng-container *ngTemplateOutlet="cardBody"></ng-container>
    </div>
  </div>

  <div class="card-image-container">
    <div *ngIf="timelineEvent?.imageUrl" class="card-image" [style]="getCardImageStyle()"></div>
  </div>
</div>
