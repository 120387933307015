<!-- Footer -->
<footer class="footer page-footer font-small blue pt-4">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col">
        <h5 class="text-uppercase footer-title">Drawn to creation...</h5>

        <div class="drawnbro" (click)="clickLogo()">
          <div class="drawnbro-logo-container">
            <img [src]="'../../../assets/media/logos/drawnbro_logo_100px.png'" class="drawnbro-logo" />
          </div>
          <span>Pedro Quintas</span>
        </div>

        <!-- Social Media Overlay Links -->
        <div class="social-media-icons footer-social-media-icons">
          <app-button
            icon="linkedin"
            (click)="openNewTab('https://www.linkedin.com/in/pedro-o-quintas/')"
            tooltip="LinkedIn"
          />
          <app-button icon="github" (click)="openNewTab('https://github.com/spedroq')" tooltip="Github" />
        </div>
      </div>

      <!-- Grid column -->
      <div class="col">
        <ul class="list-unstyled privacy-terms-list">
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    © 2023 Copyright:
    <a href="https://www.phoquintas.com/">www.phoquintas.com</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
