import { Component, Input } from '@angular/core';
import { ComponentSize } from 'src/app/shared/models';
import { AppIcon } from 'src/app/shared/models/icon.model';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() icon: AppIcon;
  @Input() image: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() classes: string;
  @Input() size: ComponentSize = 'medium';

  constructor() {}
}
