import { EducationLog } from '../../shared/models';

export const educationLogs: EducationLog[] = [
  {
    institution: {
      name: 'The University of Glasgow',
      image: 'uofg.jpg',
      link: 'https://www.gla.ac.uk/',
    },
    degrees: [
      {
        startDate: new Date(Date.parse('09/01/2011')),
        endDate: new Date(Date.parse('06/01/2017')),
        name: 'Master in Science in Computer Science with Honours',
        generalImage: 'general/glasgow.jpg',
        documents: [
          {
            name: 'MSci degree diploma',
            description: 'Master in Science in Computer Science with Honours',
            link: '/assets/documents/MSci_Diploma_Pedro_Quintas.pdf',
          },
          {
            name: 'IBM - IT Architecture Certificate',
            description:
              'IBM issued a certificate to acknowledge the students that passed the module with a grade higher than C1, this module was completed with an A.',
            link: '/assets/documents/ibm_certificate.jfif',
          },
        ],
        projects: [
          {
            name: 'Investigating binary deep neural networks on an FPGA',
            description: "Master's project final year paper",
            link: '/assets/documents/MSci_Paper_Pedro_Quintas.pdf',
          },
          {
            name: 'Investigating and proposing new uses for Android thermal cameras',
            description: '4th year dissertation',
            link: '/assets/documents/Thermal_Camera_Uses_Dissertation.pdf',
            video: 'https://www.youtube.com/watch?v=TxA9RbwMWE4&t=465s',
          },
        ],
      },
    ],
  },
];
