import { Component, Input } from '@angular/core';
import {
  BaseTimelineEvent,
  PersonalProjectTimelineEvent,
  DateUtilsService,
} from '../../../../../../shared';

@Component({
  selector: 'app-personal-project-card',
  templateUrl: './personal-project-card.component.html',
  styleUrls: ['./personal-project-card.component.scss'],
})
export class PersonalExperienceCardComponent {
  @Input() public timelineEvent: PersonalProjectTimelineEvent;

  constructor() {}

  public calculateTimeAtEvent(timelineEvent: BaseTimelineEvent): string {
    return DateUtilsService.timePeriodString(
      timelineEvent.start,
      timelineEvent.end
    );
  }

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }
}
