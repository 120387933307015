<app-timeline-card-shell *ngIf="timelineEvent" [timelineEvent]="timelineEvent">
  <ng-template #cardBody>
    <div *ngIf="timelineEvent.degree.documents?.length" class="documents-list-container degree-documents">
      <div class="subsection-header document-header">Documents</div>
      <div
        class="document-container clickable-container"
        *ngFor="let document of timelineEvent.degree.documents"
        (click)="openNewTab(document.link)"
        [matTooltip]="document.description"
      >
        <div class="subsection-name">
          <app-pdf-link [link]="document.link" [label]="document.name"> </app-pdf-link>
        </div>
        <div *ngIf="document.image" class="subsection-image" [ngClass]="{ 'display-none': !document.image }">
          <img
            [src]="'../../../assets/media/images/document-logos/' + document.image"
            alt="document-image"
            class="company-image"
          />
        </div>
      </div>
    </div>
    <div *ngIf="timelineEvent.degree.projects?.length" class="projects-list-container degree-projects">
      <div class="subsection-header project-header">Highlighted Projects</div>
      <div
        class="project-container clickable-container"
        *ngFor="let project of timelineEvent.degree.projects"
        (click)="openNewTab(project.link)"
        [matTooltip]="project.description"
      >
        <div class="subsection-name">
          <app-pdf-link [link]="project.link" [label]="project.name"> </app-pdf-link>
        </div>
        <div *ngIf="project.image" class="subsection-image" [ngClass]="{ 'display-none': !project.image }">
          <img
            [src]="'../../../assets/media/images/project-logos/' + project.image"
            alt="project-image"
            class="company-image"
          />
        </div>
      </div>
    </div>
  </ng-template>
</app-timeline-card-shell>
