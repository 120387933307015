<div class="section-header">
  <div class="section-header-title">
    {{ fullName }}
  </div>
</div>
<div class="section-body">
  <div class="introduction-section">
    <div class="intro-container">
      {{ intro }}
    </div>

    <div class="contact-container">
      <div *ngFor="let contact of contacts">
        <div *ngIf="isLinkedIn(contact.type)" class="contact-item">
          <app-pdf-clickable [link]="contact.value">
            <app-button
              [icon]="linkedInIcon"
              (click)="openNewTab(contact.value)"
              [tooltip]="contact.type"
              size="small"
            />
          </app-pdf-clickable>
          <div class="contact-label">{{ contact.type }}</div>
        </div>

        <div *ngIf="isPhone(contact.type)" class="contact-item">
          <app-button [icon]="phoneIcon" [tooltip]="contact.type" size="small" />
          <div class="contact-label">{{ contact.value }}</div>
        </div>

        <div *ngIf="isEmail(contact.type)" class="contact-item">
          <app-button [icon]="emailIcon" [tooltip]="contact.type" size="small" />
          <div class="contact-label">{{ contact.value }}</div>
        </div>
      </div>
      <div class="contact-item">
        <app-pdf-clickable link="https://www.phoquintas.com/">
          <app-button
            icon="www"
            (click)="openNewTab('https://www.phoquintas.com/')"
            tooltip="phoquintas.com"
            size="small"
          />
        </app-pdf-clickable>
        <div class="contact-label">phoquintas.com</div>
      </div>
    </div>
  </div>
</div>
