import {
  AppIcon,
  ManagementSkillType,
  PersonalProject,
  TechSkillType,
} from '../../shared/models';

export const personalProjectLogs: PersonalProject[] = [
  {
    hideInPrintCV: true,
    image: 'drawnbro.png',
    name: 'drawnbro',
    description:
      'Created digital artworks, practising digital drawing, image composition, and colour theory. Then designed and developed a website to showcase the work.',
    startDate: new Date(Date.parse('11/01/2020')),
    generalImage: 'general/drawnbro.jpg',
    links: [
      {
        name: 'www.drawnbro.com',
        icon: AppIcon.LINK,
        link: 'https://www.drawnbro.com',
      },
      {
        name: 'Instagram',
        icon: AppIcon.INSTAGRAM,
        link: 'https://www.instagram.com/drawnbro/',
      },
    ],
    techSkills: [
      TechSkillType.ANGULAR,
      TechSkillType.CLOUDFLARE,
      TechSkillType.GIT,
      TechSkillType.GITHUB_ACTIONS,
    ],
    managementSkills: [ManagementSkillType.KANBAN],
  },
  {
    hideInPrintCV: true,
    image: 'salty-better.png',
    name: 'Salty Better',
    description:
      'Designed and developed a browser extension bot for chrome to automatically play SaltyBet.',
    startDate: new Date(Date.parse('01/28/2021')),
    endDate: new Date(Date.parse('01/31/2021')),
    generalImage: 'general/salty-better.png',
    links: [
      {
        name: 'Project source code',
        icon: AppIcon.GITHUB,
        link: 'https://github.com/spedroq/saltybetter',
      },
    ],
    techSkills: [TechSkillType.CHROME_EXTENSIONS, TechSkillType.GIT],
    managementSkills: [],
  },
  {
    image: 'pro-trainer.png',
    name: 'PROTrainer',
    description:
      'Designed and developed a trainer bot in Python to play Pokemon Revolution Online, that is able to login, train, and catch a list of selected Pokemon.',
    startDate: new Date(Date.parse('04/09/2018')),
    endDate: new Date(Date.parse('06/13/2018')),
    generalImage: 'general/pro-trainer.png',
    links: [
      {
        name: 'Project source code',
        icon: AppIcon.GITHUB,
        link: 'https://github.com/spedroq/PROTrainer',
      },
    ],
    techSkills: [TechSkillType.PYTHON, TechSkillType.GIT],
    managementSkills: [ManagementSkillType.KANBAN],
  },
  {
    image: 'bloblin.png',
    name: '"Bloblin" Game',
    description:
      'Designed and developed “Bloblin” game for Windows using C++ with SDL and OpenGL.',
    startDate: new Date(Date.parse('08/28/2017')),
    endDate: new Date(Date.parse('09/12/2017')),
    generalImage: 'general/bloblin.png',
    links: [
      {
        name: 'Published Game',
        icon: AppIcon.ITCH_IO,
        link: 'https://spedroq.itch.io/bloblin',
      },
      {
        name: 'Project source code',
        icon: AppIcon.GITHUB,
        link: 'https://github.com/spedroq/Bloblin',
      },
    ],
    techSkills: [TechSkillType.CPP, TechSkillType.OPEN_GL, TechSkillType.GIT],
    managementSkills: [],
  },
  {
    hideInPrintCV: true,
    image: 'tuga-sauce.jpg',
    name: 'Magic Dinosaur',
    description:
      'One of the creators of the registered brands "Tuga Sauce" and "Tuga Essence". Both were the first portuguese brands of e-liquids and e-liquid concentrates in retail, respectively.',
    startDate: new Date(Date.parse('09/01/2015')),
    endDate: new Date(Date.parse('09/01/2018')),
    generalImage: 'general/tuga-sauce.jpg',
    links: [
      {
        name: 'Facebook',
        icon: AppIcon.FACEBOOK,
        link: 'https://www.facebook.com/tugasauce/?fref=ts',
      },
      {
        name: 'Instagram',
        icon: AppIcon.INSTAGRAM,
        link: 'https://www.instagram.com/tugasauce/',
      },
    ],
    managementSkills: [
      ManagementSkillType.DOCUMENTATION,
      ManagementSkillType.PROCESS_DEFINEMENT,
    ],
  },
];
