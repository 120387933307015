/*
 * Icons gathered from Iconify:
 * https://icon-sets.iconify.design/
 */
export enum AppIcon {
  GITHUB = 'github',
  LINKED_IN = 'linkedin',
  FILE_EXPORT = 'file-export',
  DOWNLOAD = 'download',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  ITCH_IO = 'itch-io',
  OPEN_LINK = 'open-link',
  LINK = 'link',
  PHONE = 'phone',
  EMAIL = 'email',
}
