<mat-toolbar #toolbar id="toolbar">
  <div class="drawnbro" (click)="clickLogo()">
    <div class="drawnbro-logo-container">
      <img [src]="'../../../assets/media/logos/drawnbro_logo_100px.png'" class="drawnbro-logo" />
    </div>
    <span>Pedro Quintas</span>
  </div>

  <span class="toolbar-spacer"></span>

  <div class="toolbar-actions">
    <div *ngFor="let action of actions" class="header-action">
      <app-button icon="{{ action.icon }}" (click)="action.callback()" [tooltip]="action.label" classes="show-app" />
    </div>
  </div>
</mat-toolbar>
