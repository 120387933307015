import { Component, Input } from '@angular/core';
import {
  BaseTimelineEvent,
  WorkExperienceTimelineEvent,
  DateUtilsService,
} from '../../../../../../shared';

@Component({
  selector: 'app-work-experience-card',
  templateUrl: './work-experience-card.component.html',
  styleUrls: ['./work-experience-card.component.scss'],
})
export class WorkExperienceCardComponent {
  @Input() public timelineEvent: WorkExperienceTimelineEvent;

  constructor() {}

  public calculateTimeAtEvent(timelineEvent: BaseTimelineEvent): string {
    return DateUtilsService.timePeriodString(
      timelineEvent.start,
      timelineEvent.end
    );
  }
}
