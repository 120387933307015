import { Component } from '@angular/core';
import {
  fullName,
  introductionStatement,
  welcomeStatement,
} from 'src/app/shared';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  public introductionStatement: string = introductionStatement;
  public welcomeStatement: string = welcomeStatement;
  public profileName: string = fullName;
  public profileImageUrl: string =
    '../../../../assets/media/images/general/profile-picture.jpg';
  constructor() {}
}
