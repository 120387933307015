import { Component, Input } from '@angular/core';
import { EducationTimelineEvent } from '../../../../../../shared';

@Component({
  selector: 'app-education-card',
  templateUrl: './education-card.component.html',
  styleUrls: ['./education-card.component.scss'],
})
export class EducationCardComponent {
  @Input() public timelineEvent: EducationTimelineEvent;

  constructor() {}
}
