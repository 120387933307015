<app-timeline-card-shell *ngIf="timelineEvent" [timelineEvent]="timelineEvent">
  <ng-template #cardBody>
    <div class="skill-section">
      <div class="skill-list" *ngIf="timelineEvent.personalProject?.techSkills?.length">
        <app-clickable-skill
          *ngFor="let skill of timelineEvent.personalProject?.techSkills?.sort()"
          [name]="skill"
          classes="primary"
        ></app-clickable-skill>
      </div>
      <div class="skill-list" *ngIf="timelineEvent.personalProject?.managementSkills?.length">
        <app-clickable-skill
          *ngFor="let skill of timelineEvent.personalProject.managementSkills.sort()"
          [name]="skill"
          classes="primary"
        ></app-clickable-skill>
      </div>
    </div>
    <!-- <div class="links-section">
      <div class="subsection-header links-header">Links</div>
      <div class="project-links">
        <div *ngFor="let link of timelineEvent.personalProject.links">
          <app-button [icon]="link.icon" (click)="openNewTab(link.link)" [tooltip]="link.name" size="small" />
        </div>
      </div>
    </div> -->
  </ng-template>

  <ng-template #cardHeader>
    <div class="links">
      <div *ngFor="let link of timelineEvent.personalProject.links">
        <app-button [icon]="link.icon" (click)="openNewTab(link.link)" [tooltip]="link.name" size="small" />
      </div>
    </div>
  </ng-template>
</app-timeline-card-shell>
