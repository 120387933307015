import {
  ManagementSkillType,
  TechSkillType,
  WorkExperienceLog,
} from '../../shared/models';

export const workExperienceLogs: WorkExperienceLog[] = [
  {
    company: {
      name: 'focusbc - EMEA Google partner',
      image: 'focus-bc.jpg',
      link: 'https://www.focus-bc.com/',
    },
    positions: [
      {
        startDate: new Date(Date.parse('12/01/2022')),
        position: 'Senior Full Stack Software Engineer',
        description:
          "Responsible for the design and development of integrated solutions for UEFA's event organization. Integrated the core team responsible for Vista, a centralized platform with both a web and mobile app that unifies data across various systems. Vista features tools such as the Activity Plan for team management, Live Logging for real-time incident reporting with mobile notifications, and Event Reporting for customizable reports.",
        achievements: [],
        generalImage: 'general/uefa-focus-bc.jpg',
        projects: [
          {
            name: 'UEFA VISTA',
            // link: 'http://stadiumdb.com/news/2021/02/stadium_management_uefa_presents_a_unique_solution_for_stadium_operations',
            link: 'https://virtualvenue-events.com/',
            image: 'vista.jpg',
            video: 'https://www.youtube.com/watch?v=R6bI8HeXeFo',
          },
        ],
        techSkills: [
          TechSkillType.ANGULAR,
          TechSkillType.GCP,
          TechSkillType.CSS,
          TechSkillType.HTML,
          TechSkillType.C_SHARP,
          TechSkillType.REACT,
          TechSkillType.REACT_NATIVE,
          TechSkillType.NODE_JS,
          TechSkillType.GIT,
          TechSkillType.TYPE_ORM,
          TechSkillType.PSQL,
          TechSkillType.SCSS,
        ],
        managementSkills: [
          ManagementSkillType.AGILE_METHODOLOGY,
          ManagementSkillType.SCRUM,
          ManagementSkillType.KANBAN,
          ManagementSkillType.AZURE_DEVOPS,
          ManagementSkillType.CONFLUENCE,
          ManagementSkillType.DOCUMENTATION,
          ManagementSkillType.COACHING,
          ManagementSkillType.TEAM_COLLABORATION,
        ],
      },
      {
        startDate: new Date(Date.parse('11/01/2019')),
        endDate: new Date(Date.parse('12/01/2022')),
        position: 'Senior Full Stack Software Engineer',
        description:
          'Part of the core team that developed the Mapify platform, a web solution for managing geospatial data and real-time device integration. While contributing across the stack, my main focus was on the frontend, where I delivered key features such as most of the Mapify back-office website including the custom workflow graph management interface, the map SDK, and the mobile app for testing workflows.',
        generalImage: 'general/mapify-focus-bc.jpg',
        achievements: [],
        projects: [
          {
            name: 'Mapify',
            link: 'https://www.mapify.ai/',
            image: 'mapify.jpg',
          },
        ],
        techSkills: [
          TechSkillType.ANGULAR,
          TechSkillType.GCP,
          TechSkillType.CSS,
          TechSkillType.HTML,
          TechSkillType.NODE_JS,
          TechSkillType.GIT,
          TechSkillType.TYPE_ORM,
          TechSkillType.PSQL,
          TechSkillType.SCSS,
          TechSkillType.GOOGLE_MAPS,
          TechSkillType.DECK_GL,
        ],
        managementSkills: [
          ManagementSkillType.AGILE_METHODOLOGY,
          ManagementSkillType.SCRUM,
          ManagementSkillType.KANBAN,
          ManagementSkillType.JIRA,
          ManagementSkillType.TEAM_COLLABORATION,
        ],
      },
    ],
  },
  {
    company: {
      name: 'Daltix',
      image: 'daltix.jpg',
      link: 'https://www.daltix.com/',
    },
    positions: [
      {
        startDate: new Date(Date.parse('11/01/2018')),
        endDate: new Date(Date.parse('10/01/2019')),
        position: 'Software Engineer - Team Lead',
        description:
          'Building on my first year at Daltix, I continued to be a vital part of the Data Collection team while also assuming leadership responsibilities. My position expanded to encompass several leadership roles, including Project Lead, Product Owner, and Scrum Master. In addition to my core responsibilities, I took on a diverse array of tasks, ranging from assessing, designing, and architecting internal frameworks for large-scale web scraping to managing team dynamics, streamlining processes, creating documentation templates, and overseeing continuous integration and deployment.',
        generalImage: 'general/daltix-2.jpg',
        achievements: [],
        techSkills: [
          TechSkillType.AWS,
          TechSkillType.PYTHON,
          TechSkillType.JAVA_SCRIPT,
          TechSkillType.JENKINS,
          TechSkillType.SYSTEMS_ARCHITECTURE,
          TechSkillType.WEB_SCRAPING,
          TechSkillType.REGEX,
          TechSkillType.GIT,
          TechSkillType.PSQL,
        ],
        managementSkills: [
          ManagementSkillType.AGILE_METHODOLOGY,
          ManagementSkillType.SCRUM,
          ManagementSkillType.KANBAN,
          ManagementSkillType.JIRA,
          ManagementSkillType.COACHING,
          ManagementSkillType.CONFLUENCE,
          ManagementSkillType.DOCUMENTATION,
          ManagementSkillType.PROCESS_DEFINEMENT,
          ManagementSkillType.PRODUCT_OWNER,
          ManagementSkillType.SCRUM_MASTER,
          ManagementSkillType.TEAM_MANAGEMENT,
          ManagementSkillType.TEAM_COLLABORATION,
        ],
      },
      {
        startDate: new Date(Date.parse('10/01/2017')),
        endDate: new Date(Date.parse('11/01/2018')),
        position: 'Junior Software Engineer',
        description:
          'I was responsible for building a transformation pipe-lining framework in Python aimed at streamlining and expediting the development of Python-based solutions for extracting product information from various e-commerce websites. My role encompassed the design and development of this framework as well as the hands-on implementation and continuous maintenance of web scraping solutions to retrieve data from e-commerce websites.',
        generalImage: 'general/daltix-1.jpg',
        achievements: [],
        techSkills: [
          TechSkillType.AWS,
          TechSkillType.PYTHON,
          TechSkillType.JAVA_SCRIPT,
          TechSkillType.JENKINS,
          TechSkillType.SYSTEMS_ARCHITECTURE,
          TechSkillType.WEB_SCRAPING,
          TechSkillType.REGEX,
          TechSkillType.GIT,
          TechSkillType.PSQL,
        ],
        managementSkills: [
          ManagementSkillType.AGILE_METHODOLOGY,
          ManagementSkillType.SCRUM,
          ManagementSkillType.JIRA,
          ManagementSkillType.CONFLUENCE,
          ManagementSkillType.DOCUMENTATION,
          ManagementSkillType.TEAM_COLLABORATION,
        ],
      },
    ],
  },
  {
    hideInPrintCV: true,
    company: {
      name: 'Collab',
      image: 'collab.jfif',
      link: 'https://collab.com/',
    },
    positions: [
      {
        position: 'Software Developer Intern',
        description:
          'As an intern, I developed a WebRTC Softphone Operator Console integrated with the OneContact platform. My responsibilities encompassed coding, troubleshooting, and ensuring the successful integration of the console.',
        generalImage: 'general/collab.jpg',
        achievements: [],
        techSkills: [
          TechSkillType.C_SHARP,
          TechSkillType.HTML,
          TechSkillType.CSS,
          TechSkillType.VOIP,
          TechSkillType.SIP,
          TechSkillType.WEB_RTC,
        ],
        managementSkills: [],
        startDate: new Date(Date.parse('06/01/2015')),
        endDate: new Date(Date.parse('08/01/2015')),
      },
    ],
  },
];
