import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  constructor() {}

  public static timePeriodString(
    startDate: Date = new Date(),
    endDate: Date = new Date()
  ): string {
    const msInYear = 365 * 24 * 60 * 60 * 1000;
    const numberOfYears = Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInYear
    );
    if (numberOfYears > 1) {
      return `${numberOfYears} Years`;
    } else if (numberOfYears == 1) {
      return `${numberOfYears} Year`;
    } else {
      const msInMonth = 30 * 24 * 60 * 60 * 1000;
      const numberOfMonths = Math.round(
        Math.abs(endDate.getTime() - startDate.getTime()) / msInMonth
      );
      if (numberOfMonths > 1) {
        return `${numberOfMonths} Months`;
      } else if (numberOfMonths == 1) {
        return `${numberOfMonths} Month`;
      } else {
        return `<1 Month`;
      }
    }
  }
}
