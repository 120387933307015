export enum ContactType {
  PHONE = 'Phone',
  EMAIL = 'Email',
  LINKED_IN = 'LinkedIn',
}

export interface Contact {
  type: ContactType;
  value: string;
}
