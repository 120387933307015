<div class="section-header">
  <div class="section-header-title">Work Experience</div>
</div>
<div class="section-body">
  <div class="item-card {{ experience.hideInPrintCV ? 'show-app' : '' }}" *ngFor="let experience of experiences">
    <div class="subsection-header clickable-container" (click)="openNewTab(experience.company.link)">
      <app-pdf-clickable [link]="experience.company.link">
        <div class="subsection-image">
          <img
            [src]="'../../../assets/media/images/company-logos/' + experience.company.image"
            alt="company-image"
            class="company-image"
          />
        </div>
      </app-pdf-clickable>
      <div class="subsection-name">
        <app-pdf-link [link]="experience.company.link" [label]="experience.company.name"> </app-pdf-link>
      </div>
    </div>
    <div class="subsection-body divide">
      <div class="item-card" *ngFor="let position of experience.positions">
        <div class="subsection-header project-header">
          <div class="position-details">
            <div class="position-name">
              {{ position.position }}
            </div>
            <div class="position-dates">
              {{ position.startDate | date : "MMM YYYY" }} -
              {{ position.endDate ? (position.endDate | date : "MMM YYYY") : "ongoing" }} ({{
                calculateTimeAtPosition(position)
              }})
            </div>
          </div>
          <div *ngIf="position.projects?.length" class="projects-list-container">
            <div
              class="project-container clickable-container"
              *ngFor="let project of position.projects"
              (click)="openNewTab(project.link)"
            >
              <div class="subsection-name">
                <app-pdf-link [link]="project.link" [label]="project.name"> </app-pdf-link>
              </div>
              <div *ngIf="project.image" class="subsection-image" [ngClass]="{ 'display-none': !project.image }">
                <app-pdf-clickable [link]="project.link">
                  <img
                    [src]="'../../../assets/media/images/project-logos/' + project.image"
                    alt="project-image"
                    class="company-image"
                  />
                </app-pdf-clickable>
              </div>
            </div>
          </div>
        </div>
        <div class="subsection-body">
          <div class="subsection-description">
            {{ position.description }}
          </div>
          <div class="skills-section">
            <div *ngIf="position.techSkills.length" class="skills-list">
              {{ listToText(position.techSkills) }}
            </div>
            <div *ngIf="position.managementSkills.length" class="skills-list management-skills">
              {{ listToText(position.managementSkills) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
