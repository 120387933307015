import { Component, OnInit } from '@angular/core';
import {
  educationLogs,
  personalProjectLogs,
  workExperienceLogs,
  PdfService,
  ToolbarService,
  ToolbarAction,
} from '../../../../shared';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CvComponent implements OnInit {
  public cv = {
    workExperiences: workExperienceLogs,
    education: educationLogs,
    personalProjects: personalProjectLogs,
  };

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly pdfService: PdfService
  ) {}

  public ngOnInit(): void {
    this.toolbarService.setActions([this.getPdfExportAction()]);
  }

  private getPdfExportAction(): ToolbarAction {
    return {
      label: 'Print CV',
      icon: 'download',
      callback: () => this.onPdfExport(),
    };
  }

  public onPdfExport(): void {
    this.pdfService.print();
  }
}
