import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/shared/models/icon.model';

@Component({
  selector: 'app-clickable-skill',
  templateUrl: './clickable-skill.component.html',
  styleUrls: ['./clickable-skill.component.scss'],
})
export class ClickableSkillComponent {
  @Input() icon?: AppIcon;
  @Input() name: string;
  @Input() tooltip: string;
  @Input() classes: string;

  constructor() {}
}
