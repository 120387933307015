import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  QueryList,
} from '@angular/core';
import { BaseTimelineEvent, DateUtilsService } from '../../../../../shared';

@Component({
  selector: 'app-timeline-card-shell',
  templateUrl: './timeline-card-shell.component.html',
  styleUrls: ['./timeline-card-shell.component.scss'],
})
export class TimelineCardShellComponent {
  @ContentChild('cardHeader') cardHeader: QueryList<ElementRef>;
  @ContentChild('cardBody') cardBody: QueryList<ElementRef>;

  @Input() public timelineEvent: BaseTimelineEvent;

  constructor() {}

  public calculateTimeAtEvent(timelineEvent: BaseTimelineEvent): string {
    return DateUtilsService.timePeriodString(
      timelineEvent.start,
      timelineEvent.end
    );
  }

  public getCardImageStyle(): string {
    return `background-image: url(../../../../assets/media/images/${this.timelineEvent.imageUrl});`;
  }

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }
}
