<div class="welcome-container">
  <div class="welcome-statement">
    <div class="profile-container">
      <img [src]="profileImageUrl" class="profile-picture" />
      <div class="profile-name">{{ profileName }}</div>
    </div>
    <span class="introduction-statement-text">{{ introductionStatement }}</span>
    <span class="welcome-statement-text">{{ welcomeStatement }}</span>
  </div>
</div>
