import { Component } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { ChartTheme, SkillColorMap, SkillMap } from 'src/app/shared/models';
import { WorkExperiencePosition } from 'src/app/shared/models/work-experience.model';
import {
  ChartUtilsService,
  DateUtilsService,
  workExperienceLogs,
} from '../../../../shared';
@Component({
  selector: 'app-skills-chart',
  templateUrl: './skills-chart.component.html',
  styleUrls: ['./skills-chart.component.scss'],
})
export class SkillsChartComponent {
  public techSkills: SkillMap = {};
  public managementSkills: SkillMap = {};
  public techSkillsPolarAreaChartConfig: ChartConfiguration;
  public managementSkillsPolarAreaChartConfig: ChartConfiguration;

  constructor() {
    this.generateSkillMaps();
    this.generateSkillPolarAreaChartConfigurations();
  }

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public listToText(array: string[]): string {
    return array.sort().join(', ');
  }

  public calculateTimeAtPosition(position: WorkExperiencePosition): string {
    return DateUtilsService.timePeriodString(
      position.startDate,
      position.endDate
    );
  }

  public timestampToYear(timestamp: number): number {
    const msInYear = 365 * 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(timestamp) / (msInYear / 10)) / 10;
  }

  private generateSkillMaps(): void {
    for (const experience of workExperienceLogs) {
      for (const position of experience.positions) {
        const timeDiff =
          this.timeDifference(position.startDate, position.endDate) ?? 0;
        for (const techSkill of position.techSkills) {
          const currentValue = this.techSkills[techSkill] ?? 0;
          this.techSkills[techSkill] = currentValue + timeDiff;
        }
        for (const managementSkill of position.managementSkills) {
          const currentValue = this.managementSkills[managementSkill] ?? 0;
          this.managementSkills[managementSkill] = currentValue + timeDiff;
        }
      }
    }
  }

  private timeDifference(
    startDate: Date = new Date(),
    endDate: Date = new Date()
  ): number {
    return endDate.getTime() - startDate.getTime();
  }

  // Polar Area Chart

  private generateSkillPolarAreaChartConfigurations(): void {
    this.techSkillsPolarAreaChartConfig = this.generatePolarAreaChartConfig(
      this.generatePolarAreaChartData(this.techSkills)
      // '--primary',
      // '--primary',
      // '--dark'
    );
    this.managementSkillsPolarAreaChartConfig =
      this.generatePolarAreaChartConfig(
        this.generatePolarAreaChartData(this.managementSkills)
        // '--secondary',
        // '--secondary',
        // '--dark'
      );
  }

  private generatePolarAreaChartData(map: SkillMap): ChartData {
    const keys = this.sortAndFilterSkills(map);
    return {
      labels: keys,
      datasets: [
        {
          label: ' Years of work experience',
          data: keys.map((key) => this.timestampToYear(map[key])),
          circular: true,
          borderRadius: 50,
          borderColor: this.generateBorderColors(keys),
          backgroundColor: this.generateBackgroundColors(keys),
        },
      ],
    };
  }

  private generateBorderColors(keys: string[]): string[] {
    return keys.map((k) => `rgb(${SkillColorMap[k]})`);
  }

  private generateBackgroundColors(keys: string[]): string[] {
    return keys.map((k) => `rgba(${SkillColorMap[k]}, 0.4)`);
  }

  private generatePolarAreaChartConfig(
    data: ChartData,
    titleColor?: string,
    textColor?: string,
    keyColor?: string
  ): ChartConfiguration {
    return {
      type: 'polarArea',
      data: data,
      options: {
        ...ChartUtilsService.getChartDefaultOptions(
          titleColor,
          textColor,
          keyColor
        ),
        onClick: (_event, elements, chart) => {
          if (elements[0]) {
            // TODO: React to chart click
            // const i = elements[0].index;
            // console.log("Clicked chart:", chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]);
          }
        },
      },
    };
  }

  private sortAndFilterSkills(map: SkillMap): string[] {
    return Object.keys(map)
      .sort((a, b) => map[b] - map[a])
      .filter((key) => this.timestampToYear(map[key]) > 0.6);
  }
}
