import { Injectable } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Injectable({
  providedIn: 'root',
})
export class ChartUtilsService {
  constructor() {}

  public static getChartDefaultOptions(
    customTitleColor?: string,
    customTextColor?: string,
    customKeyColor?: string
  ): ChartOptions {
    const style = getComputedStyle(document.body);
    const titleColor = customTitleColor
      ? style.getPropertyValue(customTitleColor)
      : style.getPropertyValue('--text-100');
    const textColor = customTextColor
      ? style.getPropertyValue(customTextColor)
      : style.getPropertyValue('--text');
    const keyColor = customKeyColor
      ? style.getPropertyValue(customKeyColor)
      : style.getPropertyValue('--text');
    return {
      plugins: {
        legend: {
          align: 'start',
          labels: {
            useBorderRadius: true,
            borderRadius: 20,
            color: keyColor,
            pointStyle: 'circle',
          },
        },
        tooltip: {
          titleColor,
          bodyColor: textColor,
        },
      },
      borderColor: textColor,
      layout: {
        padding: 0,
      },
      elements: {
        arc: {
          borderWidth: 3,
          borderColor: textColor,
        },
        line: {
          borderWidth: 3,
        },
      },
    };
  }
}
