export enum TechSkillType {
  ANGULAR = 'Angular',
  AWS = 'Amazon Web Services (AWS)',
  C_SHARP = 'C#',
  CPP = 'C++',
  OPEN_GL = 'OpenGL',
  SCSS = 'Sass (SCSS)',
  CSS = 'CSS',
  CLOUDFLARE = 'Cloudflare',
  CHROME_EXTENSIONS = 'Chrome Extensions',
  DECK_GL = 'Deck.gl',
  GOOGLE_MAPS = 'Google Maps',
  GCP = 'Google Cloud Platform (GCP)',
  GML_CODE = 'GameMaker Language',
  GIT = 'Git',
  GITHUB_ACTIONS = 'GitHub Actions',
  HTML = 'HTML',
  JAVA_SCRIPT = 'JavaScript',
  JENKINS = 'Jenkins',
  NODE_JS = 'Node.js',
  PSQL = 'PostgreSQL',
  PYTHON = 'Python',
  REACT = 'React',
  REACT_NATIVE = 'React Native',
  SYSTEMS_ARCHITECTURE = 'Systems Architecture',
  SIP = 'SIP',
  TYPE_SCRIPT = 'TypeScript',
  TYPE_ORM = 'TypeORM',
  VOIP = 'VoIP',
  REGEX = 'Regular Expressions (RegEx)',
  WEB_RTC = 'WebRTC',
  WEB_SCRAPING = 'Web Scraping',
}

export enum ManagementSkillType {
  AZURE_DEVOPS = 'Azure DevOps',
  AGILE_METHODOLOGY = 'Agile Methodology',
  COACHING = 'Coaching',
  CONFLUENCE = 'Confluence',
  DOCUMENTATION = 'Documentation',
  JIRA = 'Jira',
  KANBAN = 'Kanban',
  PROCESS_DEFINEMENT = 'Process Definition',
  PRODUCT_OWNER = 'Product Owner',
  SCRUM = 'Scrum',
  SCRUM_MASTER = 'Scrum Master',
  TEAM_MANAGEMENT = 'Team Management',
  TEAM_COLLABORATION = 'Team Collaboration',
}

export const SkillColorMap = {
  [TechSkillType.ANGULAR]: '253,137,119', // Coral, matching the primary color.
  [TechSkillType.AWS]: '255,170,102', // Warm orange, slightly softer to match the theme.
  [TechSkillType.CPP]: '90,139,171', // Soft blue-gray, matching with the background.
  [TechSkillType.OPEN_GL]: '78,223,213', // Vibrant teal, matching the secondary color.
  [TechSkillType.GITHUB_ACTIONS]: '150,90,180', // Soft violet, new and distinct.
  [TechSkillType.DECK_GL]: '152, 120, 196', // A muted lavender, offering a unique contrast.
  [TechSkillType.GOOGLE_MAPS]: '83, 180, 142', // A soft green, which represents mapping and location services.
  [TechSkillType.C_SHARP]: '128,0,128', // Deep purple, retained for consistency.
  [TechSkillType.SCSS]: '233,150,177', // Soft pink, matching with the primary color.
  [TechSkillType.CSS]: '70,156,203', // Bright blue, refreshed to match the secondary theme.
  [TechSkillType.GCP]: '78,191,245', // Light blue, closer to secondary.
  [TechSkillType.GML_CODE]: '127,222,236', // Light teal, complementary to the secondary color.
  [TechSkillType.GIT]: '255,110,86', // Soft red-orange, closer to the primary color.
  [TechSkillType.HTML]: '250,104,80', // Coral red, aligned with the primary theme.
  [TechSkillType.JAVA_SCRIPT]: '252,205,84', // Soft gold, warmer to match the new theme.
  [TechSkillType.JENKINS]: '113,144,158', // Muted steel blue, fits better with the new palette.
  [TechSkillType.NODE_JS]: '118,188,138', // Soft green, less saturated for the new theme.
  [TechSkillType.PSQL]: '105,140,192', // Light steel blue, refreshed.
  [TechSkillType.PYTHON]: '98,156,194', // Muted blue, close to secondary theme.
  [TechSkillType.REACT]: '126,231,247', // Lighter blue, distinct from the old palette.
  [TechSkillType.REACT_NATIVE]: '88,195,221', // Medium teal, complementary to the secondary color.
  [TechSkillType.SYSTEMS_ARCHITECTURE]: '252,141,129', // Soft coral, matching the primary theme.
  [TechSkillType.SIP]: '135,206,235', // Light sky blue, retained.
  [TechSkillType.TYPE_SCRIPT]: '50,150,203', // Bright blue, refreshed to align with secondary.
  [TechSkillType.TYPE_ORM]: '162,100,203', // Soft violet, distinct and aligned with the new theme.
  [TechSkillType.VOIP]: '208,82,158', // Vivid pink, refreshed to align with primary.
  [TechSkillType.WEB_RTC]: '164,221,227', // Light cyan, soft and complementary.
  [TechSkillType.WEB_SCRAPING]: '211,242,246', // Pale cyan, aligns with secondary color.
  [TechSkillType.REGEX]: '82,129,169', // Medium dark blue, softened to fit the new theme.

  [ManagementSkillType.AZURE_DEVOPS]: '62,141,184', // Azure blue, refreshed to better fit the theme.
  [ManagementSkillType.AGILE_METHODOLOGY]: '182,255,182', // Light green, slightly muted.
  [ManagementSkillType.COACHING]: '255,187,183', // Soft coral-pink, aligned with primary.
  [ManagementSkillType.CONFLUENCE]: '50,150,203', // Bright blue, refreshed and aligned with secondary.
  [ManagementSkillType.DOCUMENTATION]: '255,246,236', // Soft cream, slightly lighter.
  [ManagementSkillType.JIRA]: '78,94,255', // Bright blue, aligned with the new theme.
  [ManagementSkillType.KANBAN]: '255,195,102', // Soft orange, slightly muted.
  [ManagementSkillType.PROCESS_DEFINEMENT]: '108,90,139', // Deep violet, aligned with secondary theme.
  [ManagementSkillType.PRODUCT_OWNER]: '255,145,150', // Light coral, aligned with primary.
  [ManagementSkillType.SCRUM]: '168,227,247', // Soft blue, refreshed for new theme.
  [ManagementSkillType.SCRUM_MASTER]: '255,178,156', // Soft peach, aligns with the primary color.
  [ManagementSkillType.TEAM_MANAGEMENT]: '107,173,201', // Muted teal, professional and balanced.
  [ManagementSkillType.TEAM_COLLABORATION]: '78,223,213', // Teal, matching secondary.
};

export interface SkillMap {
  [key: string]: number;
}
