import { Component, Input } from '@angular/core';

export type SkillGaugeTheme = 'text-100' | 'text' | 'primary' | 'secondary';

@Component({
  selector: 'app-skill-gauge',
  templateUrl: './skill-gauge.component.html',
  styleUrls: ['./skill-gauge.component.scss'],
})
export class SkillGaugeComponent {
  @Input() public name: string;
  @Input() public experience: string;
  @Input() public isHeader: boolean = false;
  @Input() public labelTheme: SkillGaugeTheme = 'text';
  @Input() public experienceTheme: SkillGaugeTheme = 'text';

  constructor() {}
}
