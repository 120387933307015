import { EducationDegree, EducationLog } from './education.model';
import { Institution } from './institution.model';
import { PersonalProject } from './personal-project.model';
import {
  WorkExperienceLog,
  WorkExperiencePosition,
} from './work-experience.model';

export enum TimelineEventType {
  WORK_EXPERIENCE = 'work-experience',
  EDUCATION = 'education',
  PERSONAL_PROJECT = 'personal-project',
}

export interface BaseTimelineEvent {
  start: Date;
  end?: Date;
  name: string;
  type: TimelineEventType;
  description?: string;
  entity?: Institution;
  imageUrl?: string;
}

export interface WorkExperienceTimelineEvent extends BaseTimelineEvent {
  workExperience: WorkExperienceLog;
  position: WorkExperiencePosition;
}

export interface EducationTimelineEvent extends BaseTimelineEvent {
  education: EducationLog;
  degree: EducationDegree;
}

export interface PersonalProjectTimelineEvent extends BaseTimelineEvent {
  personalProject: PersonalProject;
}
