<app-timeline-card-shell *ngIf="timelineEvent" [timelineEvent]="timelineEvent">
  <ng-template #cardBody>
    <div class="skill-section">
      <div class="skill-list" *ngIf="timelineEvent.position?.techSkills?.length">
        <app-clickable-skill
          *ngFor="let skill of timelineEvent.position?.techSkills?.sort()"
          [name]="skill"
          classes="primary"
        >
        </app-clickable-skill>
      </div>
      <div class="skill-list" *ngIf="timelineEvent.position?.managementSkills?.length">
        <app-clickable-skill
          *ngFor="let skill of timelineEvent.position?.managementSkills?.sort()"
          [name]="skill"
          classes="primary"
        >
        </app-clickable-skill>
      </div>
    </div>
  </ng-template>
</app-timeline-card-shell>
