import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToolbarAction } from 'src/app/shared/models';
import { ToolbarService } from '../../services';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public actions: ToolbarAction[];
  private actionsSubscription: Subscription;

  constructor(
    public router: Router,
    public readonly toolbarService: ToolbarService
  ) {}

  public ngOnInit(): void {
    this.actionsSubscription = this.toolbarService.actions$.subscribe(
      (actions) => (this.actions = actions)
    );
  }

  public ngOnDestroy(): void {
    this.actionsSubscription?.unsubscribe();
  }

  public clickLogo(): void {
    this.router.navigate(['home']);
  }

  public openNewTab(url: string): void {
    window.open(url, '_blank');
  }
}
