<div class="section-header">
  <div class="section-header-title">Skills</div>
</div>
<div class="section-body">
  <div class="skills-list">
    <div class="technical-skills">
      <app-skill-gauge name="Technical Skill" experience="Work experience (years)" [isHeader]="true"></app-skill-gauge>
      <div *ngFor="let techSkill of techSkillKeys">
        <app-skill-gauge
          labelTheme="primary"
          experienceTheme="text-100"
          [name]="techSkill"
          [experience]="timestampToYear(techSkills[techSkill])"
        >
        </app-skill-gauge>
      </div>
    </div>
    <div class="management-skills">
      <app-skill-gauge name="Management Skill" experience="Work experience (years)" [isHeader]="true"></app-skill-gauge>
      <div *ngFor="let managementSkill of managementSkillKeys">
        <app-skill-gauge
          labelTheme="secondary"
          experienceTheme="text-100"
          [name]="managementSkill"
          [experience]="timestampToYear(managementSkills[managementSkill])"
        >
        </app-skill-gauge>
      </div>
    </div>
  </div>
</div>
