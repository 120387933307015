import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToolbarAction } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {

  private actionsSubject = new BehaviorSubject<ToolbarAction[]>([]);
  actions$ = this.actionsSubject.asObservable();

  constructor() {}

  public registerAction(action: ToolbarAction) {
    const currentActions = this.actionsSubject.value;
    this.actionsSubject.next([...currentActions, action]);
  }

  public setActions(actions: ToolbarAction[]) {
    this.actionsSubject.next(actions);
  }

  public clearActions() {
    this.actionsSubject.next([]);
  }
}
