import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdf-clickable',
  templateUrl: './pdf-clickable.component.html',
  styleUrls: ['./pdf-clickable.component.scss'],
})
export class PdfClickableComponent {
  @Input() public link: string;

  constructor(public router: Router) {}
}
