import { Component } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { ChartTheme, SkillMap } from 'src/app/shared/models';
import { WorkExperiencePosition } from 'src/app/shared/models/work-experience.model';
import {
  ChartUtilsService,
  DateUtilsService,
  workExperienceLogs,
} from '../../../../shared';

@Component({
  selector: 'app-cv-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class CvSkillsComponent {
  public techSkills: SkillMap = {};
  public managementSkills: SkillMap = {};
  public techSkillsRadarChartConfig: ChartConfiguration;
  public managementSkillsRadarChartConfig: ChartConfiguration;

  public get techSkillKeys(): string[] {
    return this.sortAndFilterSkills(this.techSkills);
  }

  public get managementSkillKeys(): string[] {
    return this.sortAndFilterSkills(this.managementSkills);
  }

  constructor() {
    this.generateSkillMaps();
    this.generateSkillRadarChartConfigurations();
  }

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public listToText(array: string[]): string {
    return array.sort().join(', ');
  }

  public calculateTimeAtPosition(position: WorkExperiencePosition): string {
    return DateUtilsService.timePeriodString(
      position.startDate,
      position.endDate
    );
  }

  public timestampToYear(timestamp: number): number {
    const msInYear = 365 * 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(timestamp) / (msInYear / 10)) / 10;
  }

  private generateSkillMaps(): void {
    for (const experience of workExperienceLogs) {
      for (const position of experience.positions) {
        const timeDiff =
          this.timeDifference(position.startDate, position.endDate) ?? 0;
        for (const techSkill of position.techSkills) {
          const currentValue = this.techSkills[techSkill] ?? 0;
          this.techSkills[techSkill] = currentValue + timeDiff;
        }
        for (const managementSkill of position.managementSkills) {
          const currentValue = this.managementSkills[managementSkill] ?? 0;
          this.managementSkills[managementSkill] = currentValue + timeDiff;
        }
      }
    }
  }

  private timeDifference(
    startDate: Date = new Date(),
    endDate: Date = new Date()
  ): number {
    return endDate.getTime() - startDate.getTime();
  }

  // Radar Chart

  private generateSkillRadarChartConfigurations(): void {
    // Setup colors
    const style = getComputedStyle(document.body);

    const pointColor = style.getPropertyValue('--text-100');

    const techSkillColor = style.getPropertyValue('--primary');
    const techSkillColorRGB = this.hexToRgb(techSkillColor);

    const managementSkillColor = style.getPropertyValue('--secondary');
    const managementSkillColorRGB = this.hexToRgb(managementSkillColor);

    // Setup
    const techSkillChartTheme: ChartTheme = {
      backgroundColor: `rgba(${techSkillColorRGB.r}, ${techSkillColorRGB.g}, ${techSkillColorRGB.b}, 0.2)`,
      borderColor: techSkillColor,
      pointBackgroundColor: techSkillColor,
      pointBorderColor: pointColor,
      pointHoverBackgroundColor: pointColor,
      pointHoverBorderColor: techSkillColor,
    };
    this.techSkillsRadarChartConfig = this.generateRadarChartConfig(
      this.generateRadarChartData(this.techSkills, techSkillChartTheme)
    );

    const managementSkillChartTheme: ChartTheme = {
      backgroundColor: `rgba(${managementSkillColorRGB.r}, ${managementSkillColorRGB.g}, ${managementSkillColorRGB.b}, 0.2)`,
      borderColor: managementSkillColor,
      pointBackgroundColor: managementSkillColor,
      pointBorderColor: pointColor,
      pointHoverBackgroundColor: pointColor,
      pointHoverBorderColor: managementSkillColor,
    };
    this.managementSkillsRadarChartConfig = this.generateRadarChartConfig(
      this.generateRadarChartData(
        this.managementSkills,
        managementSkillChartTheme
      )
    );
  }

  private generateRadarChartData(map: SkillMap, theme: ChartTheme): ChartData {
    const keys = this.sortAndFilterSkills(map);
    return {
      labels: keys,
      datasets: [
        {
          label: 'Years of work experience',
          data: keys.map((key) => this.timestampToYear(map[key])),
          fill: true,
          ...theme,
        },
      ],
    };
  }

  private generateRadarChartConfig(data: ChartData): ChartConfiguration {
    return {
      type: 'radar',
      data: data,
      options: {
        ...ChartUtilsService.getChartDefaultOptions(),
        onClick: (_event, elements, chart) => {
          if (elements[0]) {
            // TODO: React to chart click
            // const i = elements[0].index;
            // console.log("Clicked chart:", chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]);
          }
        },
      },
    };
  }

  private hexToRgb(hex: string): { r: number; g: number; b: number } {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  private sortAndFilterSkills(map: SkillMap): string[] {
    return Object.keys(map)
      .sort((a, b) => map[b] - map[a])
      .filter((key) => this.timestampToYear(map[key]) > 0.6);
  }
}
