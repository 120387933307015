<div class="timeline-header">
  <div class="timeline-description">{{ description }}</div>
  <div class="timeline-filters">
    <div *ngFor="let eventType of eventTypes" class="event-type-filter">
      <app-text-button
        [label]="eventType"
        tooltip="Filter {{ eventType }}"
        (onClick)="toggleEventType(eventType)"
        class="{{ isEventTypeFiltered(eventType) ? 'selected' : 'removed' }}"
      ></app-text-button>
    </div>
  </div>
</div>

<div class="timeline-event-list">
  <ng-container *ngFor="let timelineEvent of timelineEvents">
    <div
      class="timeline-event-container"
      *ngIf="
        (isWorkExperience(timelineEvent) && isWorkEventTypeFiltered()) ||
        (isEducation(timelineEvent) && isEducationEventTypeFiltered()) ||
        (isPersonalProject(timelineEvent) && isPersonalProjectEventTypeFiltered())
      "
    >
      <div class="timeline-event-line-container">
        <div class="timeline-event-line"></div>
      </div>
      <div class="timeline-event-card-container">
        <app-work-experience-card
          *ngIf="isWorkExperience(timelineEvent) && isWorkEventTypeFiltered()"
          [timelineEvent]="timelineEvent"
        ></app-work-experience-card>
        <app-education-card
          *ngIf="isEducation(timelineEvent) && isEducationEventTypeFiltered()"
          [timelineEvent]="timelineEvent"
        ></app-education-card>
        <app-personal-project-card
          *ngIf="isPersonalProject(timelineEvent) && isPersonalProjectEventTypeFiltered()"
          [timelineEvent]="timelineEvent"
        ></app-personal-project-card>
      </div>
    </div>
  </ng-container>
</div>
