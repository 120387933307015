export const cvIntroductionStatement = `
    I am a senior full-stack engineer based in Lisbon.
    I bring experience from leading the data collection team at Daltix to building the Mapify platform with Focus-BC.
    Currently, I am working on a platform that assists UEFA in organizing various aspects of their events.
    Fluent in both Portuguese and English, I combine technical expertise with strong communication skills.
`;

export const introductionStatement = `
    ${cvIntroductionStatement}
    Outside of work, I enjoy playing video games, drawing, skiing, and cultivating my own chillies.
`;

export const fullName = 'Pedro Quintas';

export const welcomeStatement = 'Welcome to my curriculum website';
