import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent {
  @Input() label: string;
  @Input() tooltip: string;
  @Input() classes: string;

  @Output() onClick = new EventEmitter<void>();

  constructor() {}

  public handleOnClick() {
    this.onClick.emit();
  }
}
