import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarService } from '../../services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(
    private readonly router: Router,
    private readonly toolbarService: ToolbarService
  ) {}

  public clickLogo(): void {
    this.router.navigate(['home']);
  }

  public openNewTab(url: string): void {
    window.open(url, '_blank');
  }
}
