import { NgModule } from '@angular/core';
import * as c from './components';
import { SharedModule } from '../../shared';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'cv',
    component: c.CvComponent,
  },
];

const components = [
  c.CvComponent,
  c.CvEducationComponent,
  c.CvIntroductionComponent,
  c.CvPersonalProjectsComponent,
  c.CvSkillsComponent,
  c.CvWorkExperienceComponent,
];

@NgModule({
  declarations: [...components],
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [...components],
  providers: [],
})
export class CvModule {}
