import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/shared/models/icon.model';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() set icon(value: AppIcon) {
    this.image = `../../../assets/media/icons/${value}.svg`;
  }
  @Input() image: string;
  @Input() classes: string;
  @Input() tooltip: string;

  constructor() {}
}
