import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  public isPrintingPdf: boolean = false;

  constructor() {
    window.addEventListener('beforeprint', (_event: Event) => {
      this.onBeforePrint();
    });
    window.addEventListener('afterprint', (_event: Event) => {
      this.onAfterPrint();
    });
  }

  public print(): void {
    this.onBeforePrint();
    setTimeout(() => {
      window.print();
    });
  }

  private onBeforePrint() {
    this.isPrintingPdf = true;
  }

  private onAfterPrint() {
    this.isPrintingPdf = false;
  }
}
