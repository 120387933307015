import { Component, Input } from '@angular/core';
import { DateUtilsService } from 'src/app/shared';
import { PersonalProject } from '../../../../shared';

@Component({
  selector: 'app-cv-personal-projects',
  templateUrl: './personal-projects.component.html',
  styleUrls: ['./personal-projects.component.scss'],
})
export class CvPersonalProjectsComponent {
  @Input() protected experiences: PersonalProject[];

  constructor() {}

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public listToText(array: string[]): string {
    return array.sort().join(', ');
  }

  public calculateTimeAtPosition(position: PersonalProject): string {
    return DateUtilsService.timePeriodString(
      position.startDate,
      position.endDate
    );
  }

  public getProjectDefaultLink(experience: PersonalProject): string {
    if (!experience.links.length) {
      return;
    }
    return experience.links[0].link;
  }
}
