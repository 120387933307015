import { Component } from '@angular/core';
import { PdfService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private readonly pdfService: PdfService) {}

  public isPrintingPdf(): boolean {
    return this.pdfService.isPrintingPdf;
  }
}
