import { Component } from '@angular/core';
import {
  contactInformation,
  cvIntroductionStatement,
  AppIcon,
  Contact,
  ContactType,
  fullName,
} from '../../../../shared';

@Component({
  selector: 'app-cv-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
})
export class CvIntroductionComponent {
  public contacts: Contact[] = contactInformation;
  public linkedInIcon: AppIcon = AppIcon.LINKED_IN;
  public phoneIcon: AppIcon = AppIcon.PHONE;
  public emailIcon: AppIcon = AppIcon.EMAIL;

  public intro: string = cvIntroductionStatement;
  public fullName: string = fullName;

  constructor() {}

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public isLinkedIn(type: ContactType): boolean {
    return type == ContactType.LINKED_IN;
  }

  public isEmail(type: ContactType): boolean {
    return type == ContactType.EMAIL;
  }

  public isPhone(type: ContactType): boolean {
    return type == ContactType.PHONE;
  }
}
