import { Component, Input } from '@angular/core';
import {
  WorkExperienceLog,
  WorkExperiencePosition,
  DateUtilsService,
} from '../../../../shared';

@Component({
  selector: 'app-cv-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
})
export class CvWorkExperienceComponent {
  @Input() protected experiences: WorkExperienceLog[];

  constructor() {}

  public openNewTab(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public listToText(array: string[]): string {
    return array.sort().join(', ');
  }

  public calculateTimeAtPosition(position: WorkExperiencePosition): string {
    return DateUtilsService.timePeriodString(
      position.startDate,
      position.endDate
    );
  }
}
