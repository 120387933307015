import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdf-link',
  templateUrl: './pdf-link.component.html',
  styleUrls: ['./pdf-link.component.scss'],
})
export class PdfLinkComponent {
  @Input() public label: string;
  @Input() public link: string;

  constructor(public router: Router) {}
}
